function Terms() {
  return (
    <>
      <h1><strong>개인정보 이용약관</strong></h1>
      <p>ALLCLL은 회원님의 개인정보를 중요시하며, 개인정보의 보호와 관련하여 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 및 개인정보 보호법 등
        관련법 상의 개인정보 보호 규정을 준수하고 있습니다.

        ALLCLL은 개인정보처리방침을 통하여 회원님의 개인정보가 남용되거나 유출되지 않도록 최선을 다할 것이며,
        회원님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있고,
        개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.

        ALLCLL은 개인정보 처리방침을 홈페이지 첫 화면에 공개함으로써 회원님이 언제나 용이하게 보실 수 있도록 조치하고 있습니다.
        단, 본 개인정보 처리방침은 정부의 법령 및 지침의 변경, 또는 보다 나은 서비스의 제공을 위하여 그 내용이 변경될 수 있으니,
        회원님들께서는 홈페이지 방문 시 수시로 그 내용을 확인하여 주시기 바라며,
        ALLCLL은 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</p>
      <h2>제 1 조 개인정보 수집에 대한 동의</h2>
      <p>ALLCLL은 이용자들이 ALLCLL의 개인정보 취급방침 또는 이용약관의 내용에 대하여 「동의」버튼 또는 「취소」버튼을 클릭할 수 있는 절차를 마련하여,
        「동의」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다.</p>

      <h2>제 2 조 개인정보의 수집 및 이용 목적</h2>
      <p>"개인정보"라 함은 생존하는 개인에 관한 정보로, 성명, 이메일 주소, 전화번호 등 개인을 식별할 수 있는 정보를 말합니다.
        대부분의 ALLCLL 서비스는 별도의 사용자 등록이 없이 언제든지 볼 수 있습니다.</p>
      <p>그러나 ALLCLL은 회원서비스(모의 수강 신청 결과, 회원 정보 수정과 같이 현재 제공 중이거나 향후 제공될 로그인 기반의 서비스)등을 통하여,
        이용자들에게 맞춤 식 서비스를 비롯한 보다 더 향상된 양질의 서비스를 제공하기 위하여 이용자 개인의 정보를 수집하고 있습니다.
        ALLCLL은 이용자의 사전 동의 없이는 이용자의 개인 정보를 공개하지 않으며,
        수집된 정보는 아래와 같이 이용하고 있습니다.</p>

      <p>이용자들의 개인정보를 기반으로 보다 더 유용한 서비스를 개발할 수 있습니다.
        ALLCLL은 신규 서비스개발이나 콘텐츠의 확충 시에 기존 이용자들이 ALLCLL에 제공한 개인정보를 바탕으로
        개발해야 할 서비스의 우선 순위를 보다 더 효율적으로 정하고,
        ALLCLL은 이용자들이 필요로 할 콘텐츠를 합리적으로 선택하여 제공할 수 있습니다.</p>
      <p>ALLCLL이 제공하는 각종 정보 및 서비스 등은 대부분 무료입니다.
        ALLCLL은 이러한 무료 서비스를 제공하기 위해 광고를 유치할 수 있으며 이때 이용자들에 대한 정확한 개인정보를 바탕으로
        각 서비스나 메뉴 등에 적절하게 광고와 내용들을 전달할 수 있습니다.
        ALLCLL은 광고주들로부터 광고를 받아 광고주들이 대상으로 하려는 이용자의 유형에 맞게 광고를 보여줄 뿐,
        광고주들에게는 절대로 이용자들의 개인정보를 보여주거나 제공하지 않습니다.</p>

      <p>ALLCLL이 제공하는 서비스의 원활한 이용을 위하여 회원은 적법한 동의절차를 거쳐
        SNS 업체가 연결을 위한 정보를 ALLCLL에 제공하도록 할 수 있습니다.
        ALLCLL은 연결된 SNS를 원활한 서비스 제공을 위해 이용자에게서 권한이 허락된 범위 안에서 이를 활용할 수 있으며,
        허락되지 않은 범위에 대해서는 절대 활용하지 않습니다.
        서비스 이용을 위하여 회원으로부터 추가적인 권한의 요청을 필요로 할 경우,
        각 SNS의 인증서비스를 통하여 이에 대한 사전 동의를 반드시 구할 것 입니다.</p>


      <p><strong>회원구분에 따라서 다음의 목적을 위해서 회원정보를 수집, 이용하고 있습니다.</strong></p>
      <ol>
        <li>모든 회원공통</li>
      </ol>
      <p>필수 : 세종대학교 학번</p>
      <p>선택 : 성명, 수강 과목</p>
      <ol start={2}>
        <li>기타 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.</li>
      </ol>
      <ul>
        <li>서비스 이용기록, 접속 로그, 쿠키, 접속IP 정보, 부정 이용 방지, 비인가 사용 방지 등</li>
      </ul>
      <ol start={3}>
        <li>
          <p>회원님의 기본적 인권 침해의 우려가 있는 민감정보 (범죄경력, 유전정보 등)를 수집하지 않습니다.</p>
        </li>
        <li>
          <p>회원님이 제공하신 모든 정보는 상기 목적에 필요한 용도 이외로는 사용되지 않으며,
            수집정보의 범위나 사용목적, 용도가 변경될 시에는 반드시 회원님들께 사전동의를 구할 것 입니다.</p>
        </li>
      </ol>
      <p>위 정보는 가입 당시 정보만 아니라 정보수정으로 변경된 정보를 포함 합니다.</p>


      <h2>제 3 조 개인정보의 보유 및 이용기간</h2>
      <ol>
        <li>ALLCLL은 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 개인정보를 지체 없이 파기합니다.</li>
      </ol>
      <p>단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존 합니다.</p>
      <ul>
        <li>보존 항목 : 쿠키, 세션</li>
        <li>보존 근거 : ALLCLL의 서비스이용약관 및 개인정보취급방침에 동의</li>
        <li>보존 기간 : 로그아웃 시 삭제</li>
      </ul>
      <ol start={2}>
        <li>그리고 관계법령의 규정에 의하여 보존할 필요가 있는 경우 ALLCLL은 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.</li>
      </ol>
      <ul>
        <li>보존 항목 : 세종대학교 학번</li>
        <li>보존 근거 : ALLCLL의 서비스이용약관 및 개인정보취급방침에 동의</li>
        <li>보존 기간 : 회원으로서의 자격을 유지하는 동안</li>
      </ul>
      <p>[기타]</p>
      <ol>
        <li>계약 또는 청약철회 등에 관한 기록</li>
      </ol>
      <ul>
        <li>보존근거: 전자상거래 등에서의 소비자보호에 관한 법률</li>
        <li>보존기간: 5년</li>
      </ul>
      <ol start={2}>
        <li>대금결제 및 재화 등의 공급에 관한 기록</li>
      </ol>
      <ul>
        <li>보존근거: 전자상거래 등에서의 소비자보호에 관한 법률</li>
        <li>보존기간: 5년</li>
      </ul>
      <ol start={3}>
        <li>소비자의 불만 또는 분쟁처리에 관한 기록</li>
      </ol>
      <ul>
        <li>보존근거: 전자상거래등에서의 소비자보호에 관한 법률</li>
        <li>보존기간: 3년</li>
      </ul>
      <ol start={4}>
        <li>웹사이트 방문기록</li>
      </ol>
      <ul>
        <li>보존근거: 통신비밀보호법</li>
        <li>보존기간: 3개월</li>
      </ul>
      <h2>제 4 조 개인정보의 파기절차 및 방법</h2>
      <ol>
        <li>
          <p>회원님의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.</p>
        </li>
        <li>
          <p>파기절차</p>
        </li>
      </ol>
      <ul>
        <li>회원님이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져
          내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.
        </li>
        <li>별도 DB로 옮겨진 개인정보는 법률에 의한 경우를 제외하고는 다른 목적으로 이용되지 않습니다.</li>
      </ul>
      <ol start={3}>
        <li>파기방법</li>
      </ol>
      <ul>
        <li>전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</li>
      </ul>


      <h2>제 5 조 개인정보의 제3자 제공 및 공유</h2>
      <ol>
        <li>ALLCLL은 회원님의 개인정보를 제1조에서 고지한 범위 내에서 사용하며,
          회원님의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 제3자에게 제공하지 않습니다.
          다만, 아래의 경우에는 예외로 합니다.
        </li>
      </ol>
      <ul>
        {/*<li>이용자들이 제3자 제공에 동의한 경우</li>*/}
        <li>법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
      </ul>
      {/*<ol start={2}>*/}
      {/*  <li>ALLCLL이 제공하는 서비스를 통하여 주문 및 결제가 이루어진 경우 상담 등 거래 당사자간 원활한 의사소통과 혹은 배송 등의 거래이행을 위하여*/}
      {/*    관련된 정보를 필요한 범위 내에서 거래 당사자에게 제공합니다.*/}
      {/*  </li>*/}
      {/*</ol>*/}


      <h2>제 6 조 개인정보의 처리 위탁</h2>
      {/*<p>ALLCLL은 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, */}
      {/*  관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. */}
      {/*  현재 ALLCLL의 개인정보 위탁처리 기관 및 위탁업무 내용은 다음과 같습니다.</p>*/}
      <p>ALLCLL은 개인정보를 위탁하고 있지 않습니다.</p>

      <h2>제 7 조 회원의 권리와 그 행사방법</h2>
      <ol>
        <li>
          <p>회원님은 언제든지 등록되어 있는 본인의 개인정보를 조회하거나 수정할 수 있으며
            회원 탈퇴 절차를 통하여 개인정보 이용에 대한 동의 등을 철회할 수 있습니다.</p>
        </li>
        <li>
          <p>개인정보의 조회/수정을 위해서는 사이트의 [마이 페이지] 내의 [회원정보] 항목에서 확인 가능하며,
            가입 해지(동의철회)는 [마이 페이지] 내의 '탈퇴하기'를 통하여 탈퇴하실 수 있습니다.
            이 외에도 ALLCLL의 개인정보 보호 책임자에게 서면, 전화 또는 이메일로 연락하여
            다음 각 호의 개인정보 보호 관련 권리를 행사 할 수 있습니다.</p>

          <p> 개인정보 보호 책임자 항목은 '제 13 조 개인정보보호책임자'를 참조하시기 바랍니다.</p>
        </li>
      </ol>
      <ol>
        <li>
          <p>개인정보 열람 요구</p>
        </li>
        <li>
          <p>오류 등이 있을 경우 정정 요구</p>
        </li>
        <li>
          <p>삭제의 요구</p>
        </li>
        <li>
          <p>처리정지 요구</p>
        </li>
        <li>
          <p>회원님이 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.
            ALLCLL은 회원님의 요청에 의해 해지 또는 삭제된 개인정보는 제2조에 명시된 바에 따라 처리하고
            그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</p>
        </li>
      </ol>
      <h2>제 8 조 아동의 개인정보보호</h2>
      <p>만 14세 미만의 아동의 개인정보를 보호하기 위하여 회원가입은 만14세 이상에만 허용하여 아동의 개인정보를 수집하지 않습니다.</p>

      <p>만 14세 미만의 아동이 개인정보를 제공하고자 하는 경우에는 반드시 법정대리인의 동의를 받아야 하며,
        법정대리인은 아동의 개인정보를 조회하거나 수정할 수 있으며,
        아동의 개인정보 수집 및 이용 또는 제3자 제공에 대한 동의 철회를 요청할 수 있습니다.</p>

      <h2>제 9 조 회원의 의무</h2>
      <ol>
        <li>
          <p>회원님은 자신의 개인정보를 보호할 의무가 있으며,
            ALLCLL의 책임 없는 사유로 발생한 문제에 대하여 ALLCLL은 일체의 책임을 지지 않습니다.</p>
        </li>
        <li>
          <p>회원님의 개인정보를 최신의 상태로 정확하게 입력하시기 바랍니다.
            회원님의 부정확한 정보입력으로 발생하는 문제의 책임은 회원님 자신에게 있으며,
            타인의 주민등록번호 등 개인정보를 도용하여 서비스 이용 시
            회원자격 상실과 함께 주민등록법에 의거하여 처벌될 수 있습니다.</p>
        </li>
        <li>
          <p>회원님은 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다.
            아이디, 비밀번호를 포함한 회원님의 개인정보가 유출되지 않도록 조심하시고
            게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오.</p>
        </li>
        <li>
          <p>회원님은 『정보통신망이용촉진및정보보호등에관한법률』,
            개인정보보호법, 주민등록법 등 기타 개인정보에 관한 법률을 준수하여야 합니다.</p>
        </li>
      </ol>
      <h2>제 10 조 링크사이트</h2>
      <p>ALLCLL은 회원님께 다른 ALLCLL의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다.
        이 경우 ALLCLL은 외부웹사이트 및 자료에 대한 아무런 통제권이 없으므로
        그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다.

        ALLCLL이 포함하고 있는 링크를 통하여 타 웹사이트의 페이지로 옮겨갈 경우
        해당 웹사이트의 개인정보처리방침은 ALLCLL와 무관하므로
        새로 방문한 웹사이트의 정책을 검토해보시기 바랍니다.</p>

      <h2>제 11 조 개인정보 자동수집 장치(쿠키 등)의 설치, 운영 및 그 거부에 관한 사항</h2>
      <p>ALLCLL은 회원님들에게 보다 적절하고 유용한 서비스를 제공하기 위하여 회원님의 정보를 수시로 저장하고 불러오는 ‘쿠키(cookie)’를 사용합니다.
        쿠키란 ALLCLL의 웹사이트를 운영하는데 이용되는 서버가 회원님의 컴퓨터로 전송하는 아주 작은 텍스트 파일로서 회원님의 컴퓨터 하드디스크에 저장됩니다.
        회원님께서는 쿠키의 사용여부에 대하여 선택하실 수 있습니다.
        단, 쿠키를 사용하지 않아 생기는 서비스 사용의 문제및 제한은 ALLCLL이 책임 지지 않습니다.</p>

      <p>쿠키 설정 거부 방법</p>
      <ul>
        <li>회원님은 사용하시는 웹 브라우저의 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나,
          모든 쿠키의 저장을 거부할 수 있습니다.
          단, 쿠키의 저장을 거부할 경우 로그인이 필요한 일부 서비스의 이용에 제한이 생길 수 있음을 양지하시기 바랍니다.
        </li>
        <li>쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)</li>
      </ul>
      <ol>
        <li>
          <p>[도구] 메뉴에서[인터넷 옵션]을 선택</p>
        </li>
        <li>
          <p>[개인정보]를 클릭</p>
        </li>
        <li>
          <p>[고급]을 클릭</p>
        </li>
        <li>
          <p>쿠키 허용여부를 선택</p>
        </li>
      </ol>


      <h2>제 12 조 개인정보의 기술적/관리적 보호 대책</h2>
      <p>ALLCLL은 회원님의 개인정보를 보호하기 위하여 다음과 같은 보호 대책을 시행하고 있습니다.</p>
      <ol>
        <li>이메일 및 민감정보(비밀번호 등)의 암호화</li>
      </ol>
      <p>회원님의 비밀번호는 암호화되어 저장 및 관리되고 있습니다. 비밀번호는 오직 회원 본인만이 알 수 있으며
        개인정보를 확인 및 변경할 경우에도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
        따라서 회원님의 비밀번호가 타인에게 알려지지 않도록 각별히 주의하시기 바랍니다.</p>
      <ol start={2}>
        <li>해킹 및 컴퓨터 바이러스 등에 대비</li>
      </ol>
      <p>ALLCLL은 해킹이나 컴퓨터 바이러스에 의하여 회원님들의 개인정보가 유출되거나 훼손되는 것을 막기 위하여 필요한 보안조치를 이용하고 있으며,
        더욱 향상된 보안조치를 확보할 수 있도록 가능한 모든 기술적 방법을 구비하기 위하여 노력하고 있습니다.</p>
      <ol start={3}>
        <li>개인정보 처리자의 제한 및 교육</li>
      </ol>
      <p>ALLCLL은 개인정보를 처리하는 직원을 최소한으로 제한하고 있으며,
        관련 직원들에 대한 교육을 수시로 실시하여 본 방침의 이행 및 준수여부를 확인하고 있습니다.</p>

      <h2>제 13 조 개인정보보호책임자</h2>
      <p>회원님의 개인정보를 보호하고 개인정보와 관련된 불만 등을 처리하기 위하여 ALLCLL은 고객서비스담당 부서 및 개인정보보호책임자를 두고 있습니다.
        회원님의 개인정보와 관련한 문의사항은 아래의 고객서비스담당 부서 또는 개인정보보호책임자에게 연락하여 주시기 바랍니다.</p>
      <ul>
        <li>고객서비스담당 부서: 개발팀</li>
      </ul>
      <p>이메일: sejongallcll@gmail.com</p>
      <ul>
        <li>개인정보보호책임자 성명: 김주환</li>
      </ul>
      <p>소속/직위: 대표</p>
      <p>이메일: sejongallcll@gmail.com</p>
      <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
      <ul>
        <li>개인정보침해신고센터</li>
        <li>전화 : (국번없이) 118</li>
        <li>URL : <a href='https://privacy.kisa.or.kr/'>https://privacy.kisa.or.kr</a></li>
        <li>개인정보분쟁조정위원회</li>
        <li>전화 : 1833-6972</li>
        <li>URL : <a href='https://www.kopico.go.kr/'>https://www.kopico.go.kr</a></li>
        <li>경찰청 사이버안전국</li>
        <li>전화 : (국번없이) 182</li>
        <li>URL : <a href='https://cyberbureau.police.go.kr/'>https://cyberbureau.police.go.kr</a></li>
        <li>대검찰청 사이버수사과</li>
        <li>전화 : (국번없이) 1301</li>
        <li>URL : <a href='http://www.spo.go.kr/'>http://www.spo.go.kr</a></li>
      </ul>

      <h2>제 14 조 개인정보처리방침의 개정과 그 공지</h2>
      <p>본 방침은 2024년 08월 17일 부터 시행됩니다. 현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는
        개정 최소 7일전부터 홈페이지의 공지사항을 통해 고지할 것입니다.
        다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.</p>
    </>
  );
}

export default Terms;